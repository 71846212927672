import * as React from "react"
import Home from "../home/home";
import { graphql } from "gatsby"
import { timeShuffle } from "../timeshuffle";
import { Helmet } from "react-helmet"

// takes in graphql data returned from the query and
// shuffles it deterministically according to the current
// minute, and also filters out some number of clouds;
const transformWorkCells = function(data)
{
  // const seededCoinFlip = (seed) => {
  //   let num = getSeededRandomInt(seed, 0, 2);
  //   return num;
  // }
  // const someCloudsFilteredOut = data.filter((item, index) => {
  //   if (!item.isCloud) {
  //     return true;
  //   }
  //   const seed = thisNumberOnlyChangesEveryXMinutes(1) + index;
  //   let heads = !!seededCoinFlip(seed);
  //   if (heads) {
  //     return true;
  //   }
  //   return false;
  // });

  return timeShuffle(data, 1)
}

const IndexPage = ({data: {allMarkdownRemark: { edges }}}) => {
  let worksData = edges.map(function(edge) {
      return {  
        title: edge.node.frontmatter.title,
        date: edge.node.frontmatter.date,
        slug: edge.node.frontmatter.slug,
        isCloud: !!edge.node.frontmatter.src,
        src: edge.node.frontmatter.src
      }
    }
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Pierre Kochel</title>
      </Helmet>
      <Home data={transformWorkCells(worksData)}/>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          html
          excerpt
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            slug
            src {
              id
              publicURL
              childImageSharp {
                gatsbyImageData(
                  width: 700
                  placeholder: DOMINANT_COLOR
                  formats: [JPG]
                )
              }
            }
          }
        }
      }
    }
  }
`
