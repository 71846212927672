const thisNumberOnlyChangesEveryXMinutes = function(X)
{
    const now     = Date.now();
    const seconds = now * 0.001;
    const minutes = seconds / 60;
    const reduced = rountToNearestMultipleOfM(minutes, X);
    
    return reduced / X
}

const rountToNearestMultipleOfM = function (n, M)
{
    if(n > 0)
        return Math.ceil(n/M) * M;
    else if(n < 0)
        return Math.floor(n/M) * M;
    else
        return M;
}

function timeShuffle(array, minuteInterval)
{
    const  seed = thisNumberOnlyChangesEveryXMinutes(minuteInterval);
    return shuffle(array, seed);
}

// Not my code.
// From MDN:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random#getting_a_random_integer_between_two_values
function getSeededRandomInt(seed, min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(random(seed) * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

// Not my code. 
// The following two function were stolen from
// https://stackoverflow.com/questions/16801687/javascript-random-ordering-with-seed
function shuffle(array, seed) 
{                
    var m = array.length, t, i;
    
    let chosenIndices = [];

    // While there remain elements to shuffle…
    while (m) {
        // Pick a remaining element…
        i = Math.floor(random(seed) * m--);

        chosenIndices.push(i);
        // And swap it with the current element.
        t = array[m];
        array[m] = array[i];
        array[i] = t;
        ++seed
    }
    return array;
}

function nonSeededShuffle(initArray) {
    let array = JSON.parse(JSON.stringify(initArray));
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle...
    while (currentIndex != 0) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
}

function random(seed) {
    var x = Math.sin(seed++) * 10000; 
    return x - Math.floor(x);
}

module.exports = { 
    timeShuffle, 
    getSeededRandomInt, 
    thisNumberOnlyChangesEveryXMinutes, 
    shuffle, 
    random,
    nonSeededShuffle
};