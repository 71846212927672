import React, { useEffect }  from "react"
import { Link } from "gatsby"
import Header from "../header/header.js"
import { isMobile } from 'react-device-detect';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import "./home.scss";

const Home = ({ data }) => {
    return (
        <div className="site-wrapper">
            <Header/>
            <Works works={data}/>
        </div>
    )
}

const Works = ({ works }) => {
    return (
        <main className="works-wrapper">
            { works.map((work, index) => <WorkCell content={work} key={work.title || `cloud-${index}`}/>) }
        </main>
    );
}

const WorkCell = ({content}) => {
    let link = "/work/" + content.slug.toLowerCase();
    let cloudImage = null;

    if (content.isCloud) {
        cloudImage = getImage(content.src.childImageSharp);
    }

    const isDesktop = !isMobile;
    const useModal = isDesktop || !!content.isCloud

    return (
        <Link 
            to={link}
            className="work-cell"
            state={{
                modal: useModal
            }}
        >
            { content.isCloud ? 
                <GatsbyImage 
                    className="cloud-image"
                    image={cloudImage}
                    key={content.src.publicURL}
                    alt="Cloud"
                /> :
                <p>{content.title}</p> 
            }
        </Link>
    );
}
  
export default Home;

